// @ts-nocheck
import React, { Fragment, useContext, useEffect } from "react";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useParams } from "react-router-dom";
import { Gameplay, GetGames } from "../../api/gameManagement";
import { LoginContext } from "../../store/context/LoginProvider";
import { RandColor } from "../../components/Game/RandColor";
import { Empty } from "../../components/Game/Empty";
import Loading from "../../components/shared_comp/Loading";
import Iframe from "../../components/Game/Iframe";
import { useTranslation } from "react-i18next";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

const ProviderGame = () => {
  const [
    {
      gameManagement: { category },
    },
  ] = useContext(LoginContext);

  const [{ userData }] = useContext(LoginContext);

  // const [user_code,setUser_code] =useState(userData.user_code)
  const { cat } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const [games, setGames] = useState([]);
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(true);
  const [iframeLoading, setIframeLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");

  const handleClick = (p_code, cate_code, g_code) => {
    // console.log(p_code, cate_code, g_code);
    if (userData) {
      setIframeLoading(true);
      Gameplay(userData.user_code, p_code, cate_code, g_code)
        .then((data) => {
          let url = data.Url;
          window.open(url, "_blank");
          setIframeLoading(false);
          // console.log(data.Url);
        })
        .catch((err) => console.log(err));
    } else {
      setLoading(false);
      alert("Need to login first !");
    }
  };

  const FilterGames = () => {
    if (search) {
      return games.filter((game) =>
        game.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return games;
  };

  useEffect(() => {
    setLoading(true);
    const providerID = location.pathname.split("/").pop();
    const categoryID = category
      ?.filter((cate) => cate.name.toLowerCase().includes(cat.toLowerCase()))
      .map((a) => a.id);

    GetGames(...categoryID, providerID).then((data) => {
      data && setGames(data);
      setLoading(false);
    });
    setColor(RandColor());
  }, [cat, category, location.pathname]);

  if (!loading && !games.length) {
    return <Empty />;
  }
  return (
    <>
      {iframeLoading ? (
        <Loading loading={true} full={true} />
      ) : (
        <div className="col-12 col-md-11 mx-auto mt-3 mt-md-0">
          <div className="d-flex justify-content-center justify-content-md-end align-items-center me-md-4">
            <div className="col-10 col-md-3 border border-warning rounded-5 overflow-hidden">
              <input
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                className="searchgame text-warning col-10 py-2 ms-3 border-0 fs-6 bg-transparent"
                placeholder="Search Game ..."
              />
              <MagnifyingGlassIcon
                className="text-warning
                "
                style={{ width: "1.6rem" }}
              />
            </div>
          </div>
          <div className="w-100 mt-4 d-flex justify-content-center align-items-center flex-wrap gap-4 gap-md-5 mx-auto pb-5 mb-5">
            {!loading ? (
              FilterGames().length ? (
                FilterGames().map((game, idx) => (
                  <div
                    onClick={() =>
                      handleClick(game.p_code, game.cate_code, game.g_code)
                    }
                    className={`${color} bg-opacity-25 rounded-3 mb-4 mb-md-2 football `}
                    style={{
                      width: "10rem",
                      height: "9.5rem",
                    }}
                    key={idx}
                  >
                    <LazyLoadImage
                      width={"100%"}
                      height={"100%"}
                      style={{
                        objectFit: "cover",
                      }}
                      src={game.image}
                      effect="blur"
                      className="border border-2 shadow border-warning rounded-2 border-opacity-75 cursor-pointer"
                    />
                    <div className="text-warning font-monospace fw-bolder text-center mt-2">
                      <span className=""> {game.name.substring(0, 15)}</span>
                      <small>{game.name.length >= 15 && "..."}</small>
                    </div>
                  </div>
                ))
              ) : (
                <div className="font-monospace text-warning fs-4 mt-5 pt-5 text-center">
                  {t("noresult")}
                </div>
              )
            ) : (
              <Fragment>
                {Array.from({ length: 21 }).map((_, idx) => (
                  <div key={idx}>
                    <div
                      className={`${color} pulse bg-opacity-10 rounded-3`}
                      style={{
                        width: "10rem",
                        height: "9.3rem",
                      }}
                    ></div>
                    <div
                      className={`pulse ${color} mt-2 rounded-5 bg-opacity-10`}
                      style={{ height: "1.4rem" }}
                    ></div>
                  </div>
                ))}
              </Fragment>
            )}
          </div>
        </div>
      )}
      {showIframe && (
        <Iframe
          setIframeUrl={setIframeUrl}
          setShowIframe={setShowIframe}
          iframeUrl={iframeUrl}
        />
      )}
    </>
  );
};

export default ProviderGame;
