import { create } from "zustand";

export const useConfettie = create((set) => ({
  isConOpen: false,
  onConOpen: () => set({ isConOpen: true }),
  onConClose: () => set({ isConOpen: false }),

  isShowVD: false,
  setShowVD: () => set((state) => ({ isShowVD: !state.isShowVD })),

  pocketAmount: 0,
  setPocketAmount: (value) => set({ pocketAmount: value }),
}));
