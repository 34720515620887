// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getTransitionDetail } from "../api/historyApi";
import { LoginContext } from "../store/context/LoginProvider";
import moment from "moment";
import Loading from "../components/shared_comp/Loading";
// context

const BetSlipDetail = () => {
  const { p_code } = useParams();
  const [{ userData }] = useContext(LoginContext);
  const [slips, setSlips] = useState([]);
  const [loading, setLoading] = useState(false);
  let { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getTransitionDetail({ token: userData?.token, p_code }).then((res) => {
      setSlips(res.data);
      setLoading(false);
    });
  }, [p_code, userData]);

  if (loading && !slips.length) return <Loading full={true} loading={true} />;

  return (
    <div className="row container-fluid mx-auto" style={{ overflowX: "auto" }}>
      <table className="table" style={{ minWidth: "700px" }}>
        <thead>
          <tr className="text-dark text-center bg-warning bg-opacity-75">
            <th>{t("betslipPage.wagerID")}</th>
            <th>{t("betslipPage.beforeBalance")}</th>
            <th>{t("betslipPage.BALANCE")}</th>
            <th>{t("betslipPage.Game")}</th>
            <th>{t("betslipPage.BetAmount")}</th>
            <th>{t("betslipPage.PayoutAmount")}</th>
            <th>{t("betslipPage.winLose")}</th>
            <th>{t("betslipPage.date")}</th>
          </tr>
        </thead>
        <tbody className="bg-dark text-white text-opacity-75 text-center">
          {slips.length ? (
            slips.map((slip) => (
              <tr key={slip.id}>
                <td>{slip.wager_id}</td>
                <td>{slip.before_balance}</td>
                <td>{slip.balance}</td>
                <td>{slip.slip_game ? slip.slip_game.name : slip.game}</td>
                <td>{slip.bet_amount}</td>
                <td>{slip.payout_amount}</td>
                <td>{slip.payout_amount - slip.bet_amount}</td>
                <td>{moment(slip.created_at).format("DD-MM-YYYY")}</td>
              </tr>
            ))
          ) : (
            <tr>no data</tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BetSlipDetail;
