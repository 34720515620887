import React from "react";
import { useState } from "react";


const LiveChat = () => {
  const [open, setIsOpen] = useState(false);
  return (
    <main>
      <div onClick={() => setIsOpen(!open)} className="livechat football">
        <img
          style={{ width: "3.5rem" }}
          src="https://play.htawb2d3d.com/static/media/icon.38e76f2604a52be1ba2d.png"
          alt=""
        />
      </div>
      <div className={`${open ? "d-block" : "d-none"} lciframe`}>
        <iframe
          src="https://myvipmm.com/livechat.html"
          title="Live Chat Widget"
          className="livechatiframe"
        />
      </div>
    </main>
  );
};

export default LiveChat;
