// @ts-nocheck
import clsx from "clsx";
import React from "react";
import Modal from "react-modal";
import CountUp from "react-countup";
import { useConfettie } from "../../store/zustand";

const customStyles = {
  overlay: {
    backgroundColor: "#000000e1",
    zIndex: 9999,
  },
};

Modal.setAppElement("#root");

const CommonModal = () => {
  const { isConOpen, onConClose, pocketAmount } = useConfettie();

  return (
    <Modal
      isOpen={isConOpen}
      style={customStyles}
      className={clsx("w-100 h-100 bg-black")}
    >
      <div className="text-black d-flex flex-column">
        <div className="pkMain d-flex flex-column justify-content-start">
          <div className="w-100 mt-5 text-white text-center">
            <span className="display-1 fw-bold text-info">Congratulation!</span>{" "}
            <br />
            <div className="mt-5 display-6">You have won</div>
            <div className="fw-bold text-warning mt-5 display-1">
              <CountUp
                end={pocketAmount}
                duration={5}
                onEnd={() => {
                  setTimeout(() => onConClose(), 2000);
                }}
              />{" "}
              <span className="fs-1">ကျပ်</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CommonModal;
