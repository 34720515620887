// @ts-nocheck
import React, { useState, useContext, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useParams } from "react-router-dom";
import { LoginContext } from "../../store/context/LoginProvider";
import { GetGameProviders } from "../../api/gameManagement";
import { GAME_PROVIDER } from "../../store/actions/actionTypes";
import { BaseURL } from "../../api/apiUrl";
import { Fragment } from "react";

const Category = () => {
  const [loading, setLoading] = useState(false);
  const [
    {
      gameManagement: { category, provider },
    },
    dispatch,
  ] = useContext(LoginContext);
  const { cat } = useParams();

  useEffect(() => {
    setLoading(true);
    const data = category.filter(({ name }) =>
      name.toLowerCase().includes(cat?.toLowerCase())
    );

    data &&
      GetGameProviders(data[0]?.id).then((res) => {
        res.success && dispatch({ type: GAME_PROVIDER, payload: res.data });
        setLoading(false);
      });
  }, [category, cat, dispatch]);

  return (
    <div className="vh-100 col-md-11 mx-auto">
      <div>
        <div className="d-flex justify-content-center flex-wrap gap-5 pb-5">
          {!loading ? (
            provider?.map((data, idx) => (
              <Link
                to={`${data.id}`}
                key={idx}
                className="cursor-pointer rounded-4 mb-2 football"
              >
                <LazyLoadImage
                  effect="blur"
                  style={{
                    width: "100%",
                    height: "15rem",
                    objectFit: "cover",
                  }}
                  className=""
                  src={
                    data.id === 14 && cat === "Fishing"
                      ? `${BaseURL}/${data.sec_image}`
                      : `${BaseURL}/${data.image}`
                  }
                />
              </Link>
            ))
          ) : (
            <Fragment>
              {Array.from({ length: 8 }).map((_, idx) => (
                <div
                  key={idx}
                  className="pulse catproviderDiv bg-info bg-opacity-10 mt-5 cursor-pointer rounded-4 p-5 mb-2 shadow"
                ></div>
              ))}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default Category;
